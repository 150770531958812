<template>
  <div style="width: 100%; text-align: center">
    <img
      src="@/application/assets/images/loaders/bars.svg"
      style="width: 64px;display: initial !important"
    />
    <h4>{{ label }}</h4>
  </div>
</template>

<script>
export default {
  name: "loader",
  props: ["label"]
};
</script>
